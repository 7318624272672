import axios from 'axios';
import moment from 'moment-timezone';

const { API_URL } = require('../config');
const postError = require('./ToastMessage');

export const SaveCalEvents = async (events) => {
    if (events) {
        try {
            const postBody = {
                UserId: localStorage.getItem('userId'),
                type: 'UserEvent',
                results: []
            };

            const updatedNewEvents = events.map((event) => ({
                ...event,
                start: moment(event.start).utc().format(),
                end: moment(event.end).utc().format(),
            }));

            postBody.results.push(...updatedNewEvents);

            await axios.post(API_URL + `/api/saveUserCalendarTasks`, postBody);
        } catch (error) {
            if (error.response.status === 401) {
                postError('Please login again.');
            }
            else {
                postError('Error saving calendar event. Please try again.');
            }

            console.error('Error saving calendar event:', error);
        }
    }
}