import { toast } from 'react-toastify';

const toastStyle = {
    position: 'top-right',
    autoClose: 3000, // Close after 3 seconds
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
};

export const postSuccess = (message) => {
    toast.success(message, toastStyle);
}

export const postError = (message) => {
    toast.error(message, toastStyle);
}
