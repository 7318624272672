import React, { useState, useEffect } from 'react';
import './MultiSelectDropdown.css'; // Import CSS file
import Modal from '../modals/ModalAttributeSelection'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MultiSelectDropdown = ({ label, options, preSelectedOptions = [], onSelectionChange, handleApplyButtonClick, updated }) => {
  const [selectedOptions, setSelectedOptions] = useState(preSelectedOptions);
  const [showOptions, setShowOptions] = useState(false);

  const handleApply = () => {
    setShowOptions(false);
    handleApplyButtonClick();
  }

  const handleCloseModal = () => {
    setShowOptions(false); // Close the modal
  };

  const handleOptionClick = (option) => {
    let updatedSelection;

    if (typeof option === 'string') {
      if (selectedOptions.includes(option)) {
        updatedSelection = selectedOptions.filter((item) => item !== option);
      } else {
        updatedSelection = [...selectedOptions, option];
      }
    }
    else {
      if (selectedOptions.find(selectedOption => selectedOption.id === option.id) !== undefined) {
        updatedSelection = selectedOptions.filter((item) => item.id !== option.id);
      } else {
        updatedSelection = [...selectedOptions, option];
      }
    }

    setSelectedOptions(updatedSelection);

    // Notify parent component of the change in selection
    if (onSelectionChange) {
      onSelectionChange(updatedSelection, label);
    }
  };

  useEffect(() => {
    setSelectedOptions(preSelectedOptions || []);
  }, [preSelectedOptions]);

  return (
    <div className="grid-container-msd">
      <div className="selected-option-msd" onClick={() => setShowOptions(!showOptions)}>
        {label}
        <div className="selected-count-bubble">
          {selectedOptions.length}
        </div>
        <div className="dropdown-arrow-msd" >
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'white' }} size="lg" title="Show Selection" />
        </div>
      </div>

      <Modal
        showOptions={showOptions}
        options={options}
        selectedOptions={selectedOptions}
        handleOptionClick={handleOptionClick}
        onClose={handleCloseModal}
        handleApplyButtonClick={handleApply}
        updated={updated}
      />
    </div >
  );

};

export default MultiSelectDropdown;
