import React, { useState, useEffect } from 'react';
import './AdditionModal.css'; // Import CSS file
import axios from 'axios';
import { API_URL } from '../config';

const ModalWatchProviders = ({ isOpen, onClose, data, userCountryCode }) => {
    const [mData, setMData] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            if (data) {
                try {
                    //const URL = process.env.REACT_APP_TMDB_Image_URL + data.CinemaType.toLowerCase() + "/" + data.id + "/watch/providers?api_key=" + process.env.REACT_APP_TMDB_api_key;
                    const responseForUrl = await axios.get(`https://api.themoviedb.org/3/${data.CinemaType.toLowerCase()}/${data.id}/watch/providers?api_key=${process.env.REACT_APP_TMDB_API_KEY}&`);

                    if (responseForUrl !== undefined && responseForUrl.data !== undefined && responseForUrl.data.results !== undefined && responseForUrl.data.results[userCountryCode] !== undefined) {
                        let oTTProviders = responseForUrl.data.results[userCountryCode]
                        const allOTT = await axios.get(API_URL + '/api/getMasterOTT');


                        if (allOTT.data.length > 0) {
                            allOTT.data.forEach((provider) => {
                                if (provider.id) {
                                    Object.keys(oTTProviders).forEach((key) => {
                                        if (Array.isArray(oTTProviders[key])) {
                                            oTTProviders[key].forEach((item) => {
                                                if (item.provider_id === provider.id) {
                                                    item.url = (provider.url && provider.url !== '' && !provider.url.endsWith('.com') && !provider.url.endsWith('.com/') ? `${provider.url}${encodeURIComponent(data.title !== undefined ? data.title : data.name)}` : provider.url);
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        }
                        setMData(oTTProviders)
                    }
                }
                catch (error) {
                    //postError('Error fetching the provider.');
                }
            }
        }
        fetchData();
    }, [data, userCountryCode]);

    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={onClose}>X</button>
                        <h2>You can watch this content here:</h2>
                        {mData ?
                            <div className="eic-sub-grid">
                                {Object.keys(mData).map((key) => (
                                    <div className="eic-sub-grid-row">
                                        <div className="eic-sub-grid-header">
                                            {key !== 'link' ? key : null}
                                        </div>
                                        <div className="eic-sub-grid-row" key={key}>
                                            {key !== 'link' && mData[key].map((item) => (
                                                <div className="eic-sub-grid-column">
                                                    <div className="eic-sub-grid-image-box">
                                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                                            <img src={"https://media.themoviedb.org/t/p/original" + item.logo_path} alt={item.name} />
                                                        </a>

                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            :
                            <>
                                Couldn't find any provider where this is available...
                                <a href={`https://google.com/search?q=${encodeURIComponent("Where to watch " + data.original_language + " " + data.CinemaType + " - " + data.title)}`} target="_blank" rel="noopener noreferrer">Search here</a>
                            </>
                        }
                    </div>
                </div>
            )}
        </>
    );
};

export default ModalWatchProviders