// DisplayDecision.js

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import SwipeCards from './SwipeCards';
import ExpenseIncomeCard from './ExpenseIncomeCard'
import { postError } from '../utils/ToastMessage';
//import ollama from 'ollama/browser';


const DisplayDecision = ({ p, userId, refreshDecision, updateUserProblem, optionalParams }) => {
    const [loading, setLoading] = useState(true);

    const [cardsFromParent, setCardsFromParent] = useState(null);

    const fetchData = useCallback(async () => {
        try {
            if (refreshDecision) { }

            setLoading(true);

            let response, url;
            url = API_URL + `/api/getDecisionText/${userId}/${p}`;

            if (optionalParams !== null) {
                url = url + `/${optionalParams}`
            }

            response = await axios.get(url);

            /*
                            const aiDataObj = {
                                "model": "gemma:2b",
                                "stream": false,
                                "system": "You are a prediction bot which'll predict what a user will like in the future. You always provide well-reasoned logic to your prediction. Your'll get some user information which will tell you what the user is interested in. You may get some past likingins and dislikings of the user. You'll use these three information - user Context, past liking and past dislikings to come to a prediction. For every question give at least 5 answers in the same json as separate nodes. The format of json should be as below:[{Title: '', Description: '',Metadata: ''},{Title: '', Description: '', Metadata: ''}]",
                                "prompt": "Can you recommend me something to watch today on the televsion? More info about me that you should put in context is -" + response.data.Prompt
                            }
            
                            const responseFromOllama = await ollama.generate(aiDataObj);
                            const result = response.data;
                            result.DecisionCards2 = JSON.parse(responseFromOllama.response);
            */

            setCardsFromParent(response.data);
            setLoading(false);
            updateUserProblem(response.data.UserProblem);
        } catch (error) {
            if (error.response.status === 401) {
                postError('Please login again.');
            }
            else {
                postError('Error fetching data. Please try again.');
            }

            console.error('Error fetching decision text:', error);
        }
    }, [p, userId, updateUserProblem, refreshDecision, optionalParams]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            {loading ? (
                <div className="placeholder-cards">
                    {/* Placeholder cards */}
                    <div className="placeholder-card"></div>
                </div>
            ) : (
                <div>
                    {(cardsFromParent.UserProblem.OutputType) && (cardsFromParent.UserProblem.OutputType === 'ExpenseIncomeCard') ?
                        <ExpenseIncomeCard
                            data={cardsFromParent.DecisionCards}
                        />
                        :
                        <SwipeCards cardsFromParent={cardsFromParent} userId={userId} fetchData={fetchData} />
                    }
                </div>

            )}
        </div>
    );
};

export default DisplayDecision;
