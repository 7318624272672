import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';

const CustomDateRangePicker = ({ onSelectionChange }) => {
    const [inputValues, setInputValues] = useState(new Date());

    const onDateRangeChange = (newValue) => {
        let newSelectedYear = moment(newValue).year();
        let newSelectedMonth = moment(newValue).month() + 1;

        // Format the current year and month
        const formattedCurrentYear = newSelectedYear.toString();
        const formattedCurrentMonth = newSelectedMonth < 10 ? '0' + newSelectedMonth.toString() : newSelectedMonth.toString();
        onSelectionChange(`${formattedCurrentYear}-${formattedCurrentMonth}`);

        setInputValues(newValue);
    };

    return (
        <div className="grid-container-msd">
            <div className="grid-msd">
                <div className="input-container-msd">
                    <div className="buton-container-rbi">
                        <div className="button-toadd-rbi">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    views={['year', 'month']}
                                    label="Month and Year"
                                    minDate={new Date('2020-03-01')}
                                    maxDate={new Date('2031-03-01')}
                                    value={inputValues}
                                    onChange={(newValue) => { onDateRangeChange(newValue) }}
                                    renderInput={(params) => <TextField {...params} value={inputValues ? moment(inputValues).format('MM/YY') : ''} InputProps={{ readOnly: true }} helperText={null} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default CustomDateRangePicker;
