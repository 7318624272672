import React, { useState } from 'react';
import './Card.css';
import Modal from '../modals/ModalPastChoices';
import { Link } from 'react-router-dom';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
const { IconActions, IconData, IconColor } = require('./CardActions/ActionMapping')

const Card = ({ card, userProblem, onButtonClick, pastDecisions, onUpdateDecisionClick }) => {
  const [isSwappingOut, setIsSwappingOut] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [filterId, setFilterId] = useState(null);

  const handleButtonClick = (item, card) => {
    setIsSwappingOut(true);
    onButtonClick(item.id, item.text);

    if (item.action) {
      // Get the action class instance from the mapping
      const action = IconActions[item.action]

      // Perform the action for the action class
      action.performAction(card);
    }
  };

  const openModal = (linkIndex) => {
    setFilterId(linkIndex);
    Modal.data = pastDecisions;
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false); // Close the modal
  };

  return (
    <div className={`card ${isSwappingOut ? 'swipe-left' : ''}`}>
      <div className="card-image">

      </div>
      <div className="card-content">
        <h2>{card.title}</h2>
        <pre>{card.description}</pre>
      </div>
      <div className="card-buttons">
        {userProblem && userProblem.Buttons && userProblem.Buttons.length > 0 && (
          userProblem.Buttons.map((item, index) => (
            <div>
              <Tooltip title={item.Description} aria-label={item.Description}>
                <span className={`icon-circle ${IconColor[item.text]}`} onClick={() => handleButtonClick(item, card)}>
                  <FontAwesomeIcon key={item.id} icon={IconData[item.text]} style={{ color: 'white' }} size="2x" aria-label={item.text}></FontAwesomeIcon>
                </span>
              </Tooltip>
              <div className="view-link-container">
                <Tooltip title="Past Items" aria-label="Past Items">
                  <Link to="#" onClick={() => openModal(item.id)}><FontAwesomeIcon icon={faHistory} size="lg" style={{ color: 'blue' }} aria-label="History" /></Link>
                </Tooltip>
              </div>
            </div>
          )))}
      </div>
      <Modal isOpen={isOpen} onClose={handleCloseModal} data={pastDecisions} filterId={filterId} buttons={userProblem.Buttons} onUpdateDecisionClick={onUpdateDecisionClick} />
    </div>
  );
};

export default Card;