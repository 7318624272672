import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import { API_URL } from '../config';
import { postError, postSuccess } from '../utils/ToastMessage';
import { TextField, Button, Typography } from '@material-ui/core';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(API_URL + '/api/forgot-password', { email });
      postSuccess(response.data);
      navigate('/Login');
    } catch (error) {
      postError(error.response.data);
    }
  };

  return (
    <div className="homepage-container">
      <Header />
      <div className="login-container">
        <Typography variant="h4">Forgot Password</Typography>
        <form onSubmit={handleForgotPassword} className="login-form">
          <TextField
            label="Email"
            variant="outlined"
            value={email}
            margin="dense"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="forgot-password-link">
            <Link to="/Login">Back to Login</Link>
          </div>
          <Button variant="contained" color="primary" type="submit">
            Reset Password
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
