import React, { useState } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';

const mapTilerApiKey = process.env.REACT_APP_MapTilerApiKey;

const LocationInput = ({ onLocationSelect }) => {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchPredictions = (value) => {
        setLoading(true);
        fetch(`https://api.maptiler.com/geocoding/${value}.json?key=${mapTilerApiKey}`)
            .then(response => response.json())
            .then(data => {
                setOptions(data.features || []);
                setLoading(false);
            })
            .catch(error => {
                setOptions([]);
                setLoading(false);
            });


    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue) {
            fetchPredictions(newInputValue);
        } else {
            setOptions([]);
        }
    };

    const handleOptionSelect = (event, selectedOption) => {
        if (selectedOption) {
            const { place_name: name, context } = selectedOption;
            const location = name;
            const loc = {
                city: context.find(comp => comp.id.includes('subregion'))?.text || '',
                state: context.find(comp => comp.id.includes('region'))?.text || '',
                country: context.find(comp => comp.id.includes('country'))?.text || '',
            };
            onLocationSelect(location, loc);
        }
    };

    return (
        <Autocomplete
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={options}
            getOptionLabel={(option) => option.place_name}
            loading={loading}
            onChange={handleOptionSelect}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="State, Country"
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default LocationInput;
