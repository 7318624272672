
const AbstractAction = require('./AbstractAction');
const { SaveCalEvents } = require('../../utils/SaveCalendarEvents');

class SaveEvents extends AbstractAction {
    async performAction(events) {
        await SaveCalEvents(events);
    }
}

module.exports = SaveEvents;