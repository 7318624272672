// RepeatableBoxList.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import './RepeatableBoxList.css'; // Import CSS file
import { Link } from 'react-router-dom';
import { postError } from '../utils/ToastMessage';

const RepeatableBoxList = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  // Function to filter items based on search query
  const filteredItems = items.filter(item =>
    item.Text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Event handler for search input change
  const handleSearchInputChange = event => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(API_URL + '/api/users/getUserProblems'); // Backend endpoint
        setItems(response.data);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          postError('Please login again.');
        }
        else {
          postError('Error fetching data. Please try again.');
        }
        console.error('Error fetching items:', error);
      }
    };

    fetchItems();
  }, []);

  return (

    <div className="repeatable-box-list">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchInputChange}
        className="search-input"
      />
      {loading ? (
        // Placeholder boxes while loading
        <div className="placeholder-boxes">
          <div className="placeholder-box"></div>
          <div className="placeholder-box"></div>
          <div className="placeholder-box"></div>
          <div className="placeholder-box"></div>
        </div>
      ) : (
        <ul className="box-list">
          {filteredItems.map((item, index) => (
            <li key={index}>
              {item.OutputType !== "Link" ?
                <Link to={`/Home/p/${item.id}`} className="repeatable-box-link">
                  <button className="repeatable-box">
                    <img src={item.IconLink} alt="Icon" className="box-icon" />
                    <span className="box-text">{item.Text}</span>
                  </button>
                </Link>
                :
                <Link to={`${item.Description}`} className="repeatable-box-link">
                  <button className="repeatable-box">
                    <img src={item.IconLink} alt="Icon" className="box-icon" />
                    <span className="box-text">{item.Text}</span>
                  </button>
                </Link>
              }
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RepeatableBoxList;
