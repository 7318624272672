import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import { Grid, Typography, Icon } from '@material-ui/core';

import "./HPSwipingCards.css";

const HPSwipingCards = () => {
    return (
        <div className="section orcas" id="orcas">
            <div className="flex two">
                <div className="flexText">
                    <h2>
                        What can th genie do for me, you ask? 🚀✨
                    </h2>
                </div>
                <div className="swiper-container">
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        autoplay={{
                            delay: 4500,
                            disableOnInteraction: true,
                        }}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 100,
                            modifier: 1,
                            slideShadows: true,
                        }}
                        navigation={true}
                        pagination={true}
                        modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <Grid container spacing={2} className="custom-grid" style={{ '--bg-image': `url('./Movie.jpg')` }}>
                                <Grid item sm={9}>
                                    <Typography variant="h5" align="left" className="hp-title-cards">
                                        What should I watch on TV?
                                    </Typography>
                                </Grid>
                                <Grid item sm={3}>
                                    <Icon align="right" className="hp-icon-card">
                                        <img src="/TvRemote.gif" alt="Gif" className="gif" />
                                    </Icon>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography variant="body1" align="left" className="description">
                                        Genie learns your preferences to recommend content you'll love, maximizing your OTT subscriptions.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Grid container spacing={2} className="custom-grid" style={{ '--bg-image': `url('./food2.jpg')` }}>
                                <Grid item sm={9}>
                                    <Typography variant="h5" align="left" className="hp-title-cards">
                                        What should I eat?
                                    </Typography>
                                </Grid>
                                <Grid item sm={3}>
                                    <Icon align="right" className="hp-icon-card">
                                        <img src="/foodbowl.gif" alt="Gif" className="gif" />
                                    </Icon>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography variant="body1" align="left" className="description">
                                        Genie's AI suggests tasty meals tailored to your tastes, location, and seasonal ingredients.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Grid container spacing={2} className="custom-grid" style={{ '--bg-image': `url('./calendar.jpg')` }}>
                                <Grid item sm={9}>
                                    <Typography variant="h5" align="left" className="hp-title-cards">
                                        Organize everything in one place?
                                    </Typography>
                                </Grid>
                                <Grid item sm={3}>
                                    <Icon align="right" className="hp-icon-card">
                                        <img src="/calendar.gif" alt="Gif" className="gif" />
                                    </Icon>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography variant="body1" align="left" className="description">
                                        Genie manages tasks and events, and suggests gifts and plans for special occasions based on your calendar.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Grid container className="custom-grid" style={{ '--bg-image': `url('./Finance.jpg')` }}>
                                <Grid item xs={12} className="title-icon-container">
                                    <Typography variant="h5" align="left" className="hp-title-cards">
                                        Recommendation around Finances?
                                    </Typography>
                                    <Icon align="right" className="hp-icon-card">
                                        <img src="/sub.gif" alt="Gif" className="gif" />
                                    </Icon>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography variant="body1" align="left" className="description">
                                        Genie revolutionizes your financial future by helping manage your finances, helping slash unnecessary expenses, and provide investment opportunities.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Grid container className="custom-grid" style={{ '--bg-image': `url('./meditation.jpg')` }}>
                                <Grid item xs={12} className="title-icon-container">
                                    <Typography variant="h5" align="left" className="hp-title-cards">
                                        Recommendation mediation options?
                                    </Typography>
                                    <Icon align="right" className="hp-icon-card">
                                        <img src="/meditate.gif" alt="Gif" className="gif" />
                                    </Icon>
                                </Grid>
                                <Grid item sm={12}>
                                    <Typography variant="body1" align="left" className="description">
                                        Genie transforms your mental well-being by offering personalized meditation techniques, stress-relief strategies, and mindfulness practices.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div >
    );
};

export default HPSwipingCards;
