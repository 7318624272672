// AdditionModal.js
import React, { useState, useEffect } from 'react';
import './AdditionModal.css'; // Import CSS file
import moment from 'moment';
import { Select, MenuItem, TextField, Button, Typography, Grid, FormControl, InputLabel } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';

const AdditionModal = ({ isOpen, onClose, callingOption, onSubmitClick, attributeId, parent, optionsFromParent, existingCard }) => {
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        title: '',
        amount: '',
        notes: '',
        frequency: '',
        start: moment(),
        end: moment().add(1, 'hours'),
        customFrequency: '',
        occurances: 0,
        customProps: [],
        isActive: true,
        CallingOption: parent.Text
    });

    useEffect(() => {
        if (callingOption && callingOption === 'View') {
            setIsReadOnly(true);
            setFormData(existingCard);
        }
        else {
            setFormData(prevFormData => ({
                ...prevFormData,
            }))
        }

    }, [callingOption, existingCard]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newEvents = generateRecurringEvents(formData.title, formData.start, formData.end, formData.frequency, formData.customFrequency, formData.occurances, formData.amount, formData.notes);

        const postBody = {
            type: 'UserDecisionMap',
            AttributeId: attributeId,
            results: newEvents
        };

        // Notify the parent about addition of a value
        if (onSubmitClick) {
            await onSubmitClick(postBody);
        }

        // Close the modal once the action is complete
        onClose(); // Close the modal after submission
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const generateRecurringEvents = (name, start, end, frequency, customFreq, occurances, amount, notes) => {
        let freq, amt;
        let multiplyFactor = 1;
        const recurrenceEvents = [];

        switch (frequency) {
            case 'daily':
                freq = 'days';
                amt = (Number(amount) * 30).toString();
                break;
            case 'weekly':
                freq = 'weeks';
                amt = (Number(amount) * 4).toString();
                break;
            case 'monthly':
                freq = 'months';
                amt = amount;
                break;
            case 'yearly':
                freq = 'years';
                amt = (Number(amount) / 12).toString();
                break;
            case 'custom':
                freq = 'days';
                amt = (Number(amount) * (30 / customFreq)).toString();
                multiplyFactor = customFreq;
                break;
            default:
                amt = amount;
                break;
        }

        recurrenceEvents.push({
            title: name,
            start: moment(start),
            end: moment(end),
            amount: amt,
            frequency: frequency,
            occurances: occurances,
            customFrequency: customFreq,
            isActive: true,
            notes: notes,
            CallingOption: parent.Text,
            type: 'Finance'
        });

        if (frequency !== '') {
            for (let count = 1; count < occurances; count++) {
                let currmo, endmo;

                currmo = moment(start).add(multiplyFactor * (count), freq);
                endmo = moment(end).add(multiplyFactor * (count), freq);

                recurrenceEvents.push({
                    title: name,
                    start: currmo,
                    end: endmo,
                    amount: amt,
                    frequency: frequency,
                    occurances: occurances,
                    customFrequency: customFreq,
                    isActive: true,
                    notes: notes,
                    CallingOption: parent.Text,
                    type: 'Finance'
                });
            }
        }

        return recurrenceEvents;
    };

    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={onClose}>X</button>
                        <Typography variant="h5" gutterBottom>{parent.Description}</Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        options={optionsFromParent}
                                        getOptionLabel={(option) => option}
                                        value={formData.title}
                                        onChange={(event, newValue) => {
                                            setFormData(prevFormData => ({
                                                ...prevFormData,
                                                title: newValue,
                                            }));
                                        }}
                                        readOnly={isReadOnly}
                                        disablePortal
                                        onInputChange={(event, newInputValue) => {
                                            setFormData(prevFormData => ({
                                                ...prevFormData,
                                                title: newInputValue,
                                            }));
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Name" variant="outlined" />
                                        )}
                                        openOnFocus
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Amount"
                                        name="amount"
                                        variant="outlined"
                                        value={formData.amount}
                                        type="number"
                                        InputProps={{
                                            readOnly: isReadOnly
                                        }}
                                        onChange={handleChange}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        type="date"
                                        name="start"
                                        label="Start Date"
                                        value={moment(formData.start).format('YYYY-MM-DD')}
                                        onChange={(date) => setFormData({
                                            ...formData, start: moment(formData.start).set({
                                                year: moment(date.target.value).format('YYYY'),
                                                month: moment(date.target.value).add(-1, 'months').format('MM'),
                                                date: moment(date.target.value).format('DD')
                                            }).format('YYYY-MM-DDTHH:mm:ss')
                                        })
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: isReadOnly
                                        }}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={5}>
                                    <TextField
                                        type="date"
                                        label="End Date"
                                        name="end"
                                        value={moment(formData.end).format('YYYY-MM-DD')}
                                        onChange={(date) => setFormData({
                                            ...formData, end: moment(formData.end).set({
                                                year: moment(date.target.value).format('YYYY'),
                                                month: moment(date.target.value).add(-1, 'months').format('MM'),
                                                date: moment(date.target.value).format('DD')
                                            }).format('YYYY-MM-DDTHH:mm:ss')
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            readOnly: isReadOnly
                                        }}
                                        fullWidth
                                        required
                                    />
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <FormControl fullWidth>
                                        <InputLabel>Repeat</InputLabel>
                                        <Select
                                            value={formData.frequency}
                                            label='Frequency'
                                            name="frequency"
                                            MenuProps={{
                                                style: { zIndex: 35001 }
                                            }}
                                            readOnly={isReadOnly}
                                            onChange={handleChange}
                                        >
                                            <MenuItem value="monthly">Monthly</MenuItem >
                                            <MenuItem value="daily">Daily</MenuItem >
                                            <MenuItem value="weekly">Weekly</MenuItem >
                                            <MenuItem value="yearly">Yearly</MenuItem >
                                            <MenuItem value="custom">Custom</MenuItem >
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {formData.frequency !== '' && (
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            label="How many times"
                                            type="number"
                                            name="occurances"
                                            variant="outlined"
                                            value={formData.occurances}
                                            onChange={handleChange}
                                            inputProps={{ min: 2 }}
                                            InputProps={{
                                                readOnly: isReadOnly
                                            }}
                                        />
                                    </Grid>
                                )}
                                {formData.frequency === 'custom' && (
                                    <Grid item xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            label="Repeat after (days)"
                                            type="number"
                                            name="customFrequency"
                                            variant="outlined"
                                            value={formData.customFrequency}
                                            onChange={handleChange}
                                            inputProps={{ min: 2 }}
                                            InputProps={{
                                                readOnly: isReadOnly
                                            }}
                                        />
                                    </Grid>
                                )}
                                {formData.frequency !== '' ?
                                    <Typography>
                                        * Repeat this event,
                                        {
                                            formData.frequency !== 'custom' ?
                                                " " + formData.frequency + ", " :
                                                (
                                                    formData.customFrequency === '' ?
                                                        " please select after how many days to repeat the event, " :
                                                        " every " +
                                                        formData.customFrequency +
                                                        " days, "
                                                )
                                        }
                                        for the next
                                        {
                                            formData.occurances !== undefined ?
                                                " " + formData.occurances +
                                                " times."
                                                :
                                                " please select how many time"
                                        }
                                    </Typography> :
                                    <></>
                                }
                                <Grid item xs={12}>
                                    {!isReadOnly ?
                                        <Button type="submit" variant="contained" color="primary" >
                                            Add
                                        </Button>
                                        : <></>
                                    }
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div >
            )}
        </>
    );
};

export default AdditionModal;
