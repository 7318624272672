import React, { useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails, useMediaQuery,
    useTheme, Typography,
} from '@mui/material';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

const TaskView = ({ events, onEventClick }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Group events by type
    const groupedEvents = events.reduce((acc, event) => {
        acc[event.type] = acc[event.type] || [];
        acc[event.type].push(event);
        return acc;
    }, {});

    // State to manage expanded accordion panels
    const [expanded, setExpanded] = useState(() => {
        const keys = Object.keys(groupedEvents);
        return keys.length > 0 ? keys[0] : ''; // Initialize to first key or '' if empty
    });


    // Handle change in accordion expansion
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? [...expanded, panel] : expanded.filter(item => item !== panel));
    };

    // Handle click on an event row
    const handleClick = (event) => {
        onEventClick(event);
    };

    /* Handle checkbox change (for completion status)
    const handleCheckboxChange = (event, eventId) => {
        const isChecked = event.target.checked;
        // Implement logic to update event completion status
    };
    */

    return (
        <TableContainer component={Paper}>
            <Table aria-label="event table">
                <TableHead>
                    <TableRow>
                        {!isMobile && <TableCell>Start</TableCell>}
                        <TableCell>Title</TableCell>
                        {!isMobile && <TableCell>Notes</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Object.keys(groupedEvents).map((type) => (
                        <TableRow key={type}>
                            <TableCell colSpan={6}>
                                <Accordion expanded={expanded.includes(type)} onChange={handleChange(type)}>
                                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faArrowDown} style={{ color: 'black' }} size="2x" />}>
                                        <Typography variant="h6" gutterBottom>{type}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Table>
                                            <TableBody>
                                                {groupedEvents[type].map((event, index) => (
                                                    <TableRow key={event.id}>
                                                        {!isMobile && (
                                                            <TableCell
                                                                onClick={() => handleClick(event)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: event.isActive ? 'none' : 'line-through',
                                                                }}
                                                            >
                                                                {moment(event.start).format('DD-MMM-YYYY HH:mm')}
                                                            </TableCell>
                                                        )}
                                                        <TableCell
                                                            onClick={() => handleClick(event)}
                                                            style={{
                                                                cursor: 'pointer',
                                                                textDecoration: event.isActive ? 'none' : 'line-through',
                                                            }}
                                                        >
                                                            <Typography variant="h6" gutterBottom>{event.title}</Typography>
                                                        </TableCell>
                                                        {!isMobile && (
                                                            <TableCell
                                                                onClick={() => handleClick(event)}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: event.isActive ? 'none' : 'line-through',
                                                                }}
                                                            >
                                                                {event.notes}
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </AccordionDetails>
                                </Accordion>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TaskView;
