const AbstractAction = require('./AbstractAction');

class MapSearch extends AbstractAction {
    async performAction(card) {
        if (card) {
            const url = `https://www.google.com/maps/search/${encodeURIComponent(card.title)}`;
            const popup = window.open(url, '_blank');
            return popup;
        }
    }
}

module.exports = MapSearch;