import React from 'react';
import './ExpenseIncomeCard.css'; // Import CSS file
import { Typography, Grid } from '@material-ui/core';

const ExpenseIncomeCard = ({ data }) => {
    // Calculate the sum of amount where CallingOption is 'Income' and expenses
    const sumOfIncome = data !== undefined ? data
        .filter(item => item.CallingOption === 'Income') ?
        data
            .filter(item => item.CallingOption === 'Income')
            .reduce((acc, item) => acc + Number(item.amount), 0)
        :
        'Nothing added'
        :
        'Nothing added';

    const sumOfExpense = data !== undefined ? data
        .filter(item => item.CallingOption === 'Expense') ?
        data
            .filter(item => item.CallingOption === 'Expense')
            .reduce((acc, item) => acc + Number(item.amount), 0)
        :
        'Nothing added'
        :
        'Nothing added';

    return (
        <div className="expense-income-card">
            <div className="eic-card-body">
                <div className="eic-column-fullwidth">
                    <Grid container spacing={3} className="gridContainer">
                        <Grid item xs={6} className="bordered-grid centered-text">
                            <Typography variant="h5" style={{ color: sumOfIncome < sumOfExpense ? 'red' : 'black' }}>
                                Liabilities: {(sumOfExpense / 1).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} className="bordered-grid centered-text">
                            <Typography variant="h5" style={{ color: sumOfIncome > sumOfExpense ? 'green' : 'black' }}>
                                Assets: {(sumOfIncome / 1).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="eic-card-body">
                <div className="eic-column-fullwidth">
                    <Grid container spacing={3} className="gridContainer">
                        <Grid item xs={5}>
                            <Typography variant="h5" className="bordered-grid centered-text">
                                Title
                            </Typography>
                        </Grid>

                        <Grid item xs={5}>
                            <Typography variant="h5" className="bordered-grid centered-text">
                                Amount
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>

                        </Grid>
                    </Grid>
                    {data && data.map((item, index) => (
                        <Grid container spacing={3} className="gridContainer">
                            <Grid item xs={5} className="bordered-grid">
                                <Typography variant="h6">{item.title}</Typography>
                            </Grid>

                            <Grid item xs={5} className="bordered-grid">
                                <Typography variant="h6" >
                                    {(item.amount / 1).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
                                </Typography>
                            </Grid>
                            <Grid item xs={2} className="bordered-grid">
                                <Typography variant="h6">{(item.CallingOption === 'Expense' ? 'Cr' : 'Db')}</Typography>
                            </Grid>
                        </Grid>
                    ))}
                </div>
            </div>
        </div >
    );
};

export default ExpenseIncomeCard;
