import React from 'react';
import { useParams } from 'react-router-dom';
import AttributePopulation from '../components/AttributePopulation';
import Header from '../components/Header';

const Decision = () => {
  const { id } = useParams();

  return (
    <div className="homepage-container">
      <Header />
      <AttributePopulation p={id} />
    </div>

  );
};

export default Decision;
