import React from 'react';
import { useParams } from 'react-router-dom';
import CalendarTaskControl from '../components/CalendarTasks/CalendarTask';
import Header from '../components/Header';

const CalendarTask = () => {
    const { id } = useParams();

    return (
        <div className="homepage-container">
            <Header />
            <CalendarTaskControl p={id} />
        </div>
    );
};

export default CalendarTask;
