import React, { useEffect } from 'react';
import { Paper } from '@material-ui/core';
import './Section1.css'; // Import the CSS file
import './Section1Anim.css';
import Header from '../Header';
import Parallax, { Layer } from "react-parallax-scroll";


const Section1 = () => {
    useEffect(() => {
        const scrollOrder = [1, 2, 3, 4]; // Define the desired scroll order

        scrollOrder.forEach((order) => {
            const items = document.querySelectorAll(`.scroll-item${order}`);
            items.forEach((item) => {
                if (item) {
                    setTimeout(() => {
                        item.classList.add('visible');
                    }, order * 1000); // Adjust the delay as needed (milliseconds)
                }
            })
        });
    }, []);

    return (
        <Paper className="section1-container" elevation={3}>
            <div className="header">
                <Header customHeightMultiplier="2"></Header>
                <div className="intro">
                    <div className='hp-img-container'>
                        <img src="/genie-single-loop.gif" className='hp-img' alt="Gif" />
                    </div>
                    <div>
                        <h1>
                            Order My Aaka!
                        </h1>
                        <h2 className="scroll-item scroll-item2">
                            Rise with a New Dawn of AI magic.
                        </h2>
                    </div>

                </div>
                <div className='scroll-item scroll-item3'>
                    <h2>
                        Your personal genie is here to grant your wishes - from movie nights to meditation mornings, from food picks to financial tips.
                        The AI genie that listens, suggests, and organizes.
                    </h2>
                </div>
                <div className='scroll-item scroll-item4'>
                    <h2>
                        Ready to wave your magical tech wand, where every recommendation feels like a wish come true?
                    </h2>

                </div>
                <Parallax>
                    <div className="circles">
                        <Layer settings={{ speed: -0.1, type: "translateY" }}>
                            <div className="circle1" />
                        </Layer>
                        <Layer settings={{ speed: -0.4, type: "translateY" }}>
                            <div className="circle2" />
                        </Layer>
                        <Layer settings={{ speed: 0.2, type: "translateY" }}>
                            <div className="circle3" />
                        </Layer>
                        <Layer settings={{ speed: -0.8, type: "translateY" }}>
                            <div className="circle4" />
                        </Layer>
                        <Layer settings={{ speed: 0.5, type: "translateY" }}>
                            <div className="circle5" />
                        </Layer>
                        <Layer settings={{ speed: -1, type: "translateY" }}>
                            <div className="circle6" />
                        </Layer>
                    </div>
                </Parallax>
                <div className="sun scroll-item scroll-item2" />
                <div className="wavesContainer scroll-item scroll-item1">
                    <svg
                        className="waves"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28"
                        preserveAspectRatio="none"
                        shapeRendering="auto"
                    >
                        <defs>
                            <path
                                id="gentle-wave"
                                d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                            />
                        </defs>
                        <g className="parallax">
                            <use
                                xlinkHref="#gentle-wave"
                                x="48"
                                y="0"
                                fill="rgba(255,255,255,0.7"
                            />
                            <use
                                xlinkHref="#gentle-wave"
                                x="48"
                                y="3"
                                fill="rgba(255,255,255,0.5)"
                            />
                            <use
                                xlinkHref="#gentle-wave"
                                x="48"
                                y="5"
                                fill="rgba(255,255,255,0.3)"
                            />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                        </g>
                    </svg>
                </div>
            </div>
        </Paper >
    );
};

export default Section1;
