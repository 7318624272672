// LoginPage.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Header from '../components/Header'
import axios from 'axios';
import './Login.css'
import { postError } from '../utils/ToastMessage';
import { TextField, Button, Typography } from '@material-ui/core';
import { API_URL } from '../config';


const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(API_URL + '/api/auth/login', { email, password });

            if (response.data === '') {
                //throw "error";
            }

            localStorage.setItem('token', response.data.token);
            localStorage.setItem('userId', response.data.userId);

            // Apply authorization token to every request if logged in
            axios.defaults.headers.common['Authorization'] = response.data.token;

            // Redirect to the home protected page
            navigate('/Home');
        } catch (error) {
            // Delete auth header
            delete axios.defaults.headers.common['Authorization'];

            if (error.response.status === 404) {
                navigate("/signup")
            }
            if (error.response.status === 401) {
                postError('Either email or password was incorrect.')
            }
            if (error.response.status === 400) {
                postError(error.response.data)
                navigate('/Home');
            }
            else {
                postError(error.response.data);
            }
            console.error('Login failed:', error.response.data.message);
            // Handle login error (display error message, clear form fields, etc.)
        }
    };

    return (
        <div className="homepage-container">
            <Header />
            <div className="login-container">
                <Typography variant="h4">Login</Typography>
                <form onSubmit={handleLogin} className="login-form">
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={email}
                        margin="dense"
                        onChange={(e) => setEmail(e.target.value.toUpperCase())}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={password}
                        margin="dense"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="forgot-password-link">
                        <Link to="/ForgotPassword">Forgot Password</Link>
                    </div>
                    <Button variant="contained" color="primary" type="submit">
                        Login
                    </Button>
                </form>
                <div className="sign-up-link">
                    <label>Don't have an account? </label>
                    <Link to="/signup">Sign up here.</Link>
                </div>
            </div>

        </div>
    );
};

export default LoginPage;
