import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment-timezone';
import { API_URL } from '../../config';
//import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import axios from 'axios';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { postError } from '../../utils/ToastMessage';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { Typography } from '@material-ui/core';
import FinModal from '../../modals/AdditionModal';
import NewEventModal from '../../modals/NewCalendarEvent';
import ErrorBoundary from '../../utils/ErrorBoundary';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CustomTagCalendarView.css'

import { Tabs, Tab, Box } from '@mui/material';
import TaskView from './TaskView';

const localizer = momentLocalizer(moment);

const MyCalendar = () => {
    const [events, setEvents] = useState([]);
    const [userId] = useState(localStorage.getItem('userId'));
    const [loading, setLoading] = useState(true);
    const [placeholder, setPlaceholder] = useState(null);
    //const [calEvents, setCalEvents] = useState(null);
    const [finEvents, setFinEvents] = useState(null);
    const [dateRange, setDateRange] = useState({ start: null, end: null });
    const [tabIndex, setTabIndex] = useState(0);

    const handleCloseFinModal = () => {
        //setIsFinModalOpen(false); // Close the modal
        setPlaceholder(<></>);
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const fetchEvents = useCallback(async (startDate, endDate) => {
        try {
            setLoading(true);
            const response = await axios.get(API_URL + `/api/getUserCalendarTaskEvents/${userId}/${startDate.toISOString()}/${endDate.toISOString()}`);

            const userTimezone = moment.tz.guess(); // Get user's current timezone

            let allEvents = [];

            if (response && response.data) {
                if (response.data.calEvents[0] && response.data.calEvents[0].results && response.data.calEvents[0].results.length > 0) {
                    //setCalEvents(response.data.calEvents[0]);
                    const temp = response.data.calEvents[0].results.map(obj => {
                        // Create a new object with the existing attributes plus the new one
                        return {
                            ...obj,
                            start: moment.utc(obj.start).tz(userTimezone).format(),
                            end: moment.utc(obj.end).tz(userTimezone).format(),
                        };
                    });

                    allEvents.push(...temp);
                }

                if (response.data.finEvents[0] && response.data.finEvents[0].results && response.data.finEvents[0].results.length > 0) {
                    setFinEvents(response.data.finEvents[0]);
                    const temp2 = response.data.finEvents[0].results.map(obj => {
                        // Create a new object with the existing attributes plus the new one
                        return {
                            ...obj,
                            start: moment.utc(obj.start).tz(userTimezone).format(),
                            end: moment.utc(obj.end).tz(userTimezone).format(),
                        };
                    });

                    allEvents.push(...temp2);
                }
                setEvents(prevEvents => {
                    const mergedEvents = [...prevEvents, ...allEvents];
                    const uniqueEvents = Array.from(
                        new Set(
                            mergedEvents
                                .filter(e => e.id !== undefined && e.id !== null)
                                .map(e => e.id)
                        )
                    ).map(id => mergedEvents.find(e => e.id === id));
                    return uniqueEvents;
                });
            }
            setLoading(false);
        } catch (error) {
            if (error.response.status === 401) {
                postError('Please login again.');
            }
            else {
                postError('Error fetching calendar data. Please try again.');
            }

            console.error('Error fetching calendar data:', error);
        }
    }, [userId]);

    useEffect(() => {
        const startDate = moment(new Date()).startOf('month');
        const endDate = moment(new Date()).endOf('month');
        setDateRange({ startDate, endDate });
        fetchEvents(startDate, endDate);
    }, [fetchEvents]);

    const handleNavigate = (newDate, view) => {
        // Fetch events for the new time period when the user navigates
        const newStart = moment(newDate).startOf(view);
        const newEnd = moment(newDate).endOf(view);
        //const mon = startDate.month() + 1;// Months are 0-based, so add 1
        //const formattedCurrentMonth = mon < 10 ? '0' + mon.toString() : mon.toString();

        const currentStart = moment(dateRange.start);
        const currentEnd = moment(dateRange.end);

        if (newStart.isSameOrAfter(currentStart) && newEnd.isSameOrBefore(currentEnd)) {
            // The new range is within the currently cached range, no need to fetch
            setDateRange({ start: newStart, end: newEnd });
        } else {
            // Fetch new events for the new range and merge
            setDateRange({ start: newStart, end: newEnd });
            fetchEvents(newStart, newEnd);
        }

        //fetchEvents(startDate, endDate);
    };

    const handleSelectSlot = ({ start, end }) => {
        setPlaceholder(<NewEventModal isOpen={true} onClose={handleCloseFinModal} onSubmitClick={saveEvent} existingCard={{ "start": moment(start), "end": moment(start).add(30, 'minutes') }} />);
    };

    const saveEvent = async (newEvents) => {
        try {
            const postBody = {
                UserId: userId,
                type: 'UserEvent',
                results: []
            };

            const updatedNewEvents = newEvents.map((event) => ({
                ...event,
                start: moment(event.start).utc().format(),
                end: moment(event.end).utc().format(),
            }));

            postBody.results.push(...updatedNewEvents);

            await axios.post(API_URL + `/api/saveUserCalendarTasks`, postBody);
            setEvents([...events, ...updatedNewEvents]);
        } catch (error) {
            if (error.response.status === 401) {
                postError('Please login again.');
            }
            else {
                postError('Error saving calendar event. Please try again.');
            }

            console.error('Error saving calendar event:', error);
        }
    };

    // Function to color code events based on certain logic
    const eventStyleGetter = (event) => {
        // Add your color logic here based on event properties
        let color = '';
        if (event.type === 'Calendar Event') {
            color = '#1d0ea7';
        } else if (event.type === 'Finance') {
            color = 'green';
        } else if (event.type === 'Food') {
            color = 'darkcyan';
        } else if (event.type === 'Workout') {
            color = 'crimson';
        } else {
            color = 'orange';
        }
        return {
            style: {
                backgroundColor: color,
            },
        };
    };
    /*
        const moveEvent = useCallback(
            ({ event, start, end, isAllDay: droppedOnAllDaySlot = false }) => {
                const { allDay } = event
                if (!allDay && droppedOnAllDaySlot) {
                    event.allDay = true
                }
                if (allDay && !droppedOnAllDaySlot) {
                    event.allDay = false;
                }
    
                setEvents((prev) => {
                    const existing = prev.find((ev) => ev.id === event.id) ?? {}
                    const filtered = prev.filter((ev) => ev.id !== event.id)
                    return [...filtered, { ...existing, start, end, allDay: event.allDay }]
                })
            },
            [setEvents]
        );

        const components = {
        customTag: {
            event: CustomTagView
        },
    };
        */

    // Function to handle event click
    const handleEventClick = (event) => {
        renderEventDetails(event);
    };

    // Function to render event details in flyout
    const renderEventDetails = (event) => {
        if (!event) {
            return null;
        }
        else {

            if (event.type === 'Finance') {
                setPlaceholder(<FinModal isOpen={true} onClose={handleCloseFinModal} attributeId={finEvents.AttributeId} onSubmitClick={null} parent={{ Text: "", Description: "Finance Event" }} optionsFromParent={[event.title]} existingCard={event} callingOption={"View"} />);
            }
            else {
                setPlaceholder(<NewEventModal isOpen={true} onClose={handleCloseFinModal} onSubmitClick={null} existingCard={event} callingOption={"View"} />);
            }
        }
    };

    return (
        <div>
            <div className='cal-Header'>
                <Typography variant="h5" gutterBottom>All your events in one place</Typography>
                <span className={`icon-circle blue`} onClick={() => { handleSelectSlot(moment()) }} >
                    <FontAwesomeIcon icon={faPlus} style={{ color: 'white' }} size="2x" title={"Add New Event"} aria-label={"Add New Event"}></FontAwesomeIcon>
                </span>
            </div>
            {loading ? (
                <div className="placeholder-cards">
                    {/* Placeholder cards */}
                    <div className="placeholder-calendar"></div>
                </div>
            ) : (
                <></>
            )}
            <ErrorBoundary>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Calendar" />
                    <Tab label="Tasks" />
                </Tabs>
                <Box role="tabpanel" hidden={tabIndex !== 0}>
                    {tabIndex === 0 && (
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                            views={{ month: true }}
                            selectable
                            resizable={false}
                            eventPropGetter={eventStyleGetter} // Color code events
                            popup
                            onSelectEvent={handleEventClick}
                            onSelectSlot={(e, f) => handleSelectSlot(moment())}
                            onNavigate={handleNavigate}
                        />
                    )}
                </Box>
                <Box role="tabpanel" hidden={tabIndex !== 1}>
                    {tabIndex === 1 && <TaskView events={events} onEventClick={handleEventClick} />}
                </Box>
            </ErrorBoundary>
            {placeholder}
        </div>
    );
};

export default MyCalendar;
