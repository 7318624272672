// frontend/src/pages/Home.js

import React from 'react';
import Header from '../components/Header';
import './Home.css'; // Import CSS file
import { Typography } from '@material-ui/core';

const Terms = () => {
    return (
        <div className="homepage-container">
            <Header />
            <Typography component="div" style={{ whiteSpace: 'pre-wrap', padding: '10px', border: '1px solid #ccc' }}>
                Thank you for choosing our services!

                These Terms of Use apply to your use of our services on this website/app and other services we offer (collectively, "Services"). By using our Services, you agree to these Terms.

                Who we are
                We're an AI research and deployment company. Our mission is to ensure that artificial general intelligence benefits everyone.

                Registration and access
                Minimum age. You must be at least 13 years old or meet the minimum age required in your country to use our Services. If you're under 18, you need your parent or legal guardians permission.

                Registration. To use our Services, you must provide accurate information when registering for an account. Keep your account credentials private, and don't share them with anyone else. If you use our Services on behalf of someone else, make sure you're authorized to accept these Terms for them.

                Using our Services
                What you can do. You can access and use our Services as long as you follow these Terms and all applicable laws. Make sure to check our Sharing & Publication Policy and other guidelines we provide.

                What you cannot do. Don't use our Services for illegal, harmful, or abusive activities. For example, don't infringe on others rights, modify or distribute our Services, or try to reverse engineer them.

                Software. Our Services may include downloadable software, like mobile apps, which may update automatically. Some of this software may include open-source components governed by their own licenses.

                Corporate domains. If you sign up for an account with a corporate email address, your account may be managed by your organization's administrator.

                Third-party Services. Our Services may include third-party software, products, or services, subject to their own terms.

                Feedback. We value your feedback and may use it without compensation.

                Content
                Your content. You can input data into our Services ("Input") and receive output based on it ("Output"). You're responsible for your Content and must ensure it complies with laws and these Terms.

                Ownership of content. You own your Input and the resulting Output.

                Similarity of content. Output may not be unique due to the nature of AI.

                Our use of content. We may use Content to improve our Services and comply with the law.

                Accuracy. Our Services may not always provide accurate output. Don't rely solely on our output for critical decisions.

                Our IP rights
                We own all rights to our Services.

                Paid accounts
                Billing. If you purchase our Services, provide accurate billing information. We'll charge your payment method automatically for renewals.

                Service credits. You can buy service credits in advance, subject to our terms.

                Cancellation. You can cancel your subscription anytime, but payments are non-refundable unless required by law.

                Changes. We may change prices with notice. If you disagree with the changes, you can cancel.

                Termination and suspension
                Termination. We may suspend or terminate your access for various reasons, including violations of these Terms.

                Appeals. You can appeal if you believe we've suspended or terminated your account wrongly.

                Discontinuation of Services
                We may discontinue our Services with notice and provide refunds for prepaid, unused Services.

                Disclaimer of warranties
                Our Services are provided “as is.” We make no warranties regarding accuracy or uninterrupted service.

                Limitation of liability
                We're not liable for indirect damages. Our total liability is limited.

                Indemnity
                If you're a business, you'll indemnify us for third-party claims related to your use of our Services.

                Dispute resolution
                We agree to resolve disputes through arbitration and waive class actions.

                Copyright complaints
                If you believe your copyright has been infringed, let us know.

                General Terms
                Assignment. You can't transfer rights or obligations under these Terms without our consent.

                Changes. We may update these Terms, and you agree to the changes by continuing to use our Services.

                Governing law. These Terms are governed by California law.
            </Typography>
        </div>
    );
};

export default Terms;
