import React, { useState } from 'react';
import './MovieCard.css';
import Modal from '../modals/ModalPastChoices';
import ModalWP from '../modals/ModalWatchProviders';
import ModalVP from '../modals/ModalVideoPlayer';
import { Link } from 'react-router-dom';
import { faPlay, faHistory, faTv } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import axios from 'axios';
const { IconAction, IconData, IconColor } = require('./CardActions/ActionMapping')

const Card = ({ card, userProblem, onButtonClick, pastDecisions, onUpdateDecisionClick, userCountryCode }) => {
    //const [isSwappingOut, setIsSwappingOut] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isWPOpen, setIsWPOpen] = useState(false);
    const [isVPOpen, setIsVPOpen] = useState(false);
    const [wPOptions, setWPOptions] = useState(null);
    const [filterId, setFilterId] = useState(null);
    const [videoUrl, setVideoUrl] = useState('');

    const handleButtonClick = (item, card) => {
        //setIsSwappingOut(true);
        onButtonClick(item.id, item.text);

        if (item.action) {
            // Get the action class instance from the mapping
            const action = IconAction[item.action]

            // Perform the action for the action class
            action.performAction(card.title);
        }
    };

    const openModal = (linkIndex) => {
        setFilterId(linkIndex);
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false); // Close the modal
    };

    const closeWPModal = () => {
        setIsWPOpen(false); // Close the modal
    };

    const closeVPModal = () => {
        setIsVPOpen(false);
    };

    const fetchWhereToWatch = async (id, cinemaType) => {
        setWPOptions(card);
        setIsWPOpen(true);
    };

    const onPlayClick = async (id, cinemaType) => {
        let url;
        if (cinemaType && cinemaType !== '') {
            const responseForUrl = await axios.get(`https://api.themoviedb.org/3/${cinemaType.toLowerCase()}/${id}/videos?api_key=${process.env.REACT_APP_TMDB_API_KEY}&language=en-US`);

            if (responseForUrl !== undefined && responseForUrl.data !== undefined && responseForUrl.data.results !== undefined && responseForUrl.data.results.length > 0) {
                url = responseForUrl.data.results.filter(item => ((item.type === 'trailer' || item.type === 'Trailer'))).map(item => item.key);
            }

            setVideoUrl(url);
        }
        setIsVPOpen(true);
    }

    return (
        <div className="control" style={{ backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url("${process.env.REACT_APP_MoveiImageURL}${card.backdrop_path}")` }}>
            <div className="image-column">
                <img src={process.env.REACT_APP_MoveiImageURL + card.poster_path} alt={card.title} />
            </div>
            <div className="content-column">
                <div className="title">{card.title !== undefined ? card.title : card.name} ({card.release_date !== undefined ? (card.release_date && card.release_date.split('-').length > 0) ? card.release_date.split('-')[0] : card.release_date : (card.first_air_date && card.first_air_date.split('-').length > 0) ? card.first_air_date.split('-')[0] : card.first_air_date})</div>
                <div className="genre">Genre: {card.genre_ids.join(', ')}</div>
                <div className="metadata">
                    <span className="language">Language: {card.original_language}</span>
                    <span className="rating">User rating: {card.vote_average}</span>
                </div>
                <div className="icons">
                    <Tooltip title="See Trailer" aria-label="See Trailer">
                        <span className="icon-circle" onClick={() => onPlayClick(card.id, card.CinemaType)}>
                            <FontAwesomeIcon icon={faPlay} style={{ color: 'white' }} size="lg" />
                        </span>
                    </Tooltip>
                    <Tooltip title="Where to watch" aria-label="Where to watch">
                        <span className="icon-circle" onClick={() => fetchWhereToWatch(card.id, "movie")}>
                            <FontAwesomeIcon icon={faTv} style={{ color: 'white' }} size="lg" />
                        </span>
                    </Tooltip>
                </div>
                <div className="overview">{card.overview}</div>
                <div className="icons">
                    {userProblem && userProblem.Buttons && userProblem.Buttons.length > 0 && (
                        userProblem.Buttons.map((item, index) => (
                            <div>
                                <Tooltip title={item.Description} aria-label={item.Description}>
                                    <span className={`icon-circle ${IconColor[item.text]}`} onClick={() => handleButtonClick(item, card)}>
                                        <FontAwesomeIcon key={item.id} icon={IconData[item.text]} style={{ color: 'white' }} size="2x" ></FontAwesomeIcon>
                                    </span>
                                </Tooltip>
                                <div className="view-link-container">
                                    <Tooltip title="Past Items" aria-label="Past Items">
                                        <Link to="#" onClick={() => openModal(item.id)}><FontAwesomeIcon icon={faHistory} size="lg" style={{ color: 'blue' }} /></Link>
                                    </Tooltip>
                                </div>
                            </div>
                        )))}
                </div>
            </div>
            <Modal isOpen={isOpen} onClose={handleCloseModal} data={pastDecisions} filterId={filterId} buttons={userProblem.Buttons} onUpdateDecisionClick={onUpdateDecisionClick} />
            <ModalWP isOpen={isWPOpen} onClose={closeWPModal} data={wPOptions} userCountryCode={userCountryCode} />
            <ModalVP isOpen={isVPOpen} onClose={closeVPModal} data={videoUrl} />
        </div>
    );
};

export default Card;