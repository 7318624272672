import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../config';
import './AttributePopulation.css'; // Import CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import MonthYearPicker from '../components/CalendarTasks/CustomDatePicker';
import MultiSelectDropdown from '../components/MultiSelectDropdown';
import RepeatedButtonsWithInput from '../components/RepeatedButtonsWithInput'
import DisplayDecision from '../components/DisplayDecision'
import { postError, postSuccess } from '../utils/ToastMessage';
import { Typography } from '@material-ui/core';
import LocationInput from './Location/LocationPicker';

const AttributesPopulation = ({ p }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updated, setUpdated] = useState(false); // State to track if the object has been updated
  const [inputChanged, setInputChanged] = useState(false);
  const [refreshDecision, setRefreshDecision] = useState(false); // State to trigger refresh of DisplayDecision component
  const [userId] = useState(localStorage.getItem('userId')); // Hardcoded user ID for now
  const [userProblem, setUserProblem] = useState(null);
  const [optionalParams, setOptionalParams] = useState(null);

  // Function to update the label text
  const updateUserProblem = useCallback((obj) => {
    setUserProblem(obj);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL + `/api/getAttributes/${userId}/${p}`);
        setData(response.data);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 401) {
          postError('Please login again.');
        }
        else {
          postError('Error fetching data. Please try again.');
        }

        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [p, userId]);

  const handleChildSelectionChange = (values, label) => {
    setData(prevData => prevData.map(item => {
      if (item.Text === label) {
        return { ...item, Default: values };
      }
      return item;
    }));
    setUpdated(true); // Set updated to true when the object has been updated
  };

  const toggleGrid = () => {
    var grid = document.querySelector('.atr-list');
    if (grid.style.display === 'none' || grid.style.display === '') {
      grid.style.display = 'block';
    } else {
      grid.style.display = 'none';
    }
  }

  const handleApplyButtonClick = async () => {
    try {
      setRefreshDecision(true);
      // Make a REST API call to update the user's profile data
      const putRequestURI = API_URL + `/api/updateUserProfile/${userId}/${p}`;
      await axios.put(putRequestURI, { data });
      console.log('Profile data updated successfully!');
      setRefreshDecision(!refreshDecision); // Toggle the state to trigger refresh of DisplayDecision component
      setUpdated(false); // Reset updated state after successful update
      postSuccess('Choices saved successfully.')
    } catch (error) {
      if (error.response.status === 401) {
        postError('Please login again.');
      }

      console.error('Error updating profile data:', error);
    }
  };

  const handleDateRangeChange = async (f) => {
    //setRefreshDecision(!refreshDecision);
    setOptionalParams(f);
  }

  const handleInputSubmitClick = async (optionData) => {
    // Adding user Id to the object
    optionData.UserId = userId;
    optionData.UserProblemId = p;

    try {
      await axios.post(API_URL + `/api/saveUserDecisionAction`, optionData);
      postSuccess('Data saved successfully.')
    } catch (error) {
      if (error.response.status === 401) {
        postError('Please login again.');
      }
      else {
        postError('Error saving data. Please try again.');
      }

      console.error('Error uploading options data:', error);
    }

    setRefreshDecision(!refreshDecision); // Toggle the state to trigger refresh of DisplayDecision component
    setUpdated(false); // Reset updated state after successful update
  };

  const handleInputChange = (optionKey, inputValue) => {
    setData(prevData => prevData.map(item => {
      if (item.Text === optionKey) {
        return { ...item, Default: inputValue };
      }
      return item;
    }));
    setInputChanged(true);
  };

  const hasNatureOpen = data ? data.some(item => item.Nature === 'Input' || item.Nature === 'Addition') : false;

  return (
    <div className="grid-container-ap">
      {loading ? (
        <div className="loading-animation">
          <div className="placeholder-boxes">
            <div className="placeholder-box"></div>
            <div className="placeholder-box"></div>
            <div className="placeholder-box"></div>
            <div className="placeholder-box"></div>
            <div className="placeholder-box"></div>
          </div>
        </div>
      ) : (
        <div>

          {userProblem && (
            <div className='title-Container'>
              <button className="description-box">
                <img src={userProblem && userProblem.IconLink} alt="Icon" className="box-icon" />
                <span className="box-text">{userProblem && userProblem.Text}</span>
              </button>
              <Typography variant="h4" style={{ alignContent: 'center', paddingLeft: '20px' }}>
                {userProblem && userProblem.Description}
              </Typography>
            </div>
          )}


          <div className="filter-icon-container">
            <div className="filter-icon" onClick={toggleGrid}>
              <FontAwesomeIcon icon={faFilter} size="2x" title="Filter Results" />
            </div>
          </div>
          <ul className={`atr-list ${hasNatureOpen ? 'show-list' : ''}`}>
            {data.map((item, index) => (
              <li key={index}>
                {item.Nature === "Addition" ? <RepeatedButtonsWithInput
                  id={`dropdown-${index}`}
                  options={item.Options}
                  onSubmitClick={handleInputSubmitClick}
                  attributeId={item.id}
                  parent={item}
                />
                  :
                  (item.Nature === "MonthYearPicker") ?
                    <MonthYearPicker
                      onSelectionChange={(e, f) => handleDateRangeChange(e, f)}
                    />

                    :
                    (item.Nature === "Input") ?
                      <div className='atr-loc-picker'>
                        <LocationInput onLocationSelect={(e) => handleInputChange(item.Text, e)} />
                      </div>
                      :
                      <MultiSelectDropdown
                        id={`dropdown-${index}`}
                        options={item.Options}
                        preSelectedOptions={item.Default}
                        onSelectionChange={handleChildSelectionChange}
                        label={item.Text}
                        handleApplyButtonClick={handleApplyButtonClick}
                        updated={updated}
                      />
                }
              </li>
            ))}
            <li className="apply-button-container">
              {inputChanged && (
                <button onClick={handleApplyButtonClick} disabled={!inputChanged} className="apply-button">
                  Apply
                </button>
              )}
            </li>
          </ul>
          <div className="decision-card-Stack">
            <DisplayDecision
              p={p}
              userId={userId}
              refreshDecision={refreshDecision}
              updateUserProblem={updateUserProblem}
              optionalParams={optionalParams}
            />
          </div>
        </div>
      )
      }
    </div >
  );
};

export default AttributesPopulation;
