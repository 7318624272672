// frontend/src/pages/Signup.js

import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import Axios for making HTTP requests
import { API_URL } from '../config';
import './Signup.css'; // Import CSS file for styling
import Header from '../components/Header'
import { postSuccess, postError } from '../utils/ToastMessage';
import { Select, MenuItem, TextField, Button, Typography, Grid, FormControl, InputLabel } from '@material-ui/core';
import LocationInput from './Location/LocationPicker';

const Signup = () => {
  const [formData, setFormData] = useState({
    email: '',
    dob: '',
    location: '',
    city: '',
    state: '',
    country: '',
    gender: '',
    password: '',
    type: 'User',
    isActive: false
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

    // Validate DOB
    if (e.target.name === 'dob') {
      const dob = new Date(e.target.value);
      const today = new Date();
      const isValidDob = dob < today;

      setErrors({
        ...errors,
        [e.target.name]: isValidDob ? '' : 'Date of Birth cannot be a future date',
      });
    }

    // Validate password
    if (e.target.name === 'password') {
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
      const isValidPassword = passwordRegex.test(e.target.value);

      setErrors({
        ...errors,
        [e.target.label]: isValidPassword ? '' : 'Password must contain at least one uppercase letter, one lowercase letter, and be at least 8 characters long',
      });
    }

    // Validate password
    if (e.target.name === 'email') {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValidEmail = emailRegex.test(e.target.value);

      setErrors({
        ...errors,
        [e.target.label]: isValidEmail ? '' : 'Please enter a valid email',
      });

      setFormData({ ...formData, [e.target.name]: e.target.value.toUpperCase() });
    }
  };

  const handleSubmit = async (e) => {

    try {
      //TOD: Add the password encryption logi
      e.preventDefault();

      const response = await axios.post(API_URL + '/api/auth/signup', formData);

      postSuccess('User signed up successfully, please check your email to activate your account');
      console.log('User signed up successfully:', response.data);
      // Optionally, navigate to a different page or display a success message

      navigate('/login')
    } catch (error) {
      if (error.response.status === 400) {
        postError('User already exist. Please login or check your email to activate account.');
      }
      else {
        postError('Error! Please try again.')

        console.error('Error signing up user:', error);
      }
    }
  };

  const handleLocationSelect = (location, fullLoc) => {
    setFormData({ ...formData, location: location, city: fullLoc.city, state: fullLoc.state, country: fullLoc.country });
  };

  return (
    <div className="homepage-container">
      <Header />
      <div className="login-container">
        <Typography variant="h4">Sign up</Typography>
        <form onSubmit={handleSubmit} className="login-form">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                variant="outlined"
                value={formData.email}
                name="email"
                margin="dense"
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                label="Password"
                type="password"
                variant="outlined"
                value={formData.password}
                margin="dense"
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Name"
                name="name"
                variant="outlined"
                value={formData.name}
                margin="dense"
                onChange={handleChange}
                error={!!errors.name}
                helperText={errors.name}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="date"
                name="dob"
                label="Date of birth"
                value={formData.dob}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!errors.dob}
                helperText={errors.dob}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  value={formData.gender}
                  label="Gender"
                  name="gender"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <LocationInput onLocationSelect={handleLocationSelect} />
            </Grid>
            <Grid item xs={12} sm={2}>
              <input
                type='checkbox'
                label="terms"
                name="terms"
                onChange={(e) => (setIsChecked(e.target.checked))}
              />
            </Grid>
            <Grid item xs={12} sm={10}>
              <label>Accept our </label>
              <Link to="/terms" target="_blank" rel="noopener noreferrer" >Terms and Conditions.</Link>
            </Grid>
          </Grid>
          <Button variant="contained" color="primary" type="submit" disabled={!isChecked}>
            Sign Up
          </Button>
        </form>
        <div className="sign-up-link">
          <label>Already have an account? </label>
          <Link to="/login">Login here.</Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
