import React from 'react';
import { Grid } from '@material-ui/core';
import Section1 from '../components/HomePage/Section1';
import HPSwipingCards from '../components/HomePage/HPSwipingCards'

import './HomePage.css'; // Import the CSS file

const Homepage = () => {
    return (
        <div className="homepage-container">
            <Grid container className="hp-grid-container">
                <Grid item xs={12}>
                    <Section1 />
                </Grid>
                <Grid item xs={12}>
                    <HPSwipingCards></HPSwipingCards>
                </Grid>
                <Grid item xs={12}>
                </Grid>
            </Grid>

        </div>
    );
};

export default Homepage;
