import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import { API_URL } from '../config';
import { postError, postSuccess } from '../utils/ToastMessage';
import { TextField, Button, Typography } from '@material-ui/core';

const ResetPassword = () => {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            const apiURLFormatted = `${API_URL}/api/reset-password`
            const response = await axios.post(apiURLFormatted, {
                password,
                token
            });
            postSuccess(response.data);
            navigate('/Login');
        } catch (error) {
            postError(error.response.data);
        }
    };

    return (
        <div className="homepage-container">
            <Header />
            <div className="login-container">
                <Typography variant="h4">Change Password</Typography>
                <form onSubmit={handleResetPassword} className="login-form">
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={password}
                        margin="dense"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <TextField
                        label="Confirm New Password"
                        type="password"
                        variant="outlined"
                        value={confirmPassword}
                        margin="dense"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <Button variant="contained" color="primary" type="submit">
                        Reset Password
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
