import React, { useRef } from 'react';


const ModalAttributeSelection = ({ showOptions, options, selectedOptions, handleOptionClick, onClose, handleApplyButtonClick, updated }) => {

    // Inside your component function
    const bottomRef = useRef(null);

    const handleCheckboxChange = () => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <React.Fragment>
                {showOptions && (
                    <div className="modal-overlay">
                        <div className="modal">
                            <div className='selected-options-container-msd'>
                                <button className="close-button" onClick={onClose}>X</button>
                                {selectedOptions.map((option, index) => (
                                    <div className='selected-options-container-msd'>
                                        {typeof option === 'string' ? (// If option is an array, render it as is
                                            <React.Fragment>
                                                <div key={index} className="selected-option-msd">
                                                    {index !== 0} {option}
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <div key={index} className="selected-option-msd">
                                                    {index !== 0} {option.name}
                                                </div>
                                            </React.Fragment>
                                        )
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className="options-list-msd">
                                {options.map((option, index) => (
                                    <div key={index} className="option-msd" onClick={() => handleOptionClick(option)}>
                                        {typeof option === 'string' ? (// If option is an array, render it as is
                                            <React.Fragment>
                                                <input type="checkbox" checked={selectedOptions.includes(option)} onChange={handleCheckboxChange} readOnly />
                                                <span>
                                                    {option}
                                                </span>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <input type="checkbox" checked={selectedOptions.some(item => item.id === option.id)} onChange={handleCheckboxChange} readOnly />
                                                <span>
                                                    {option.name} {option.english_name ? "(" + option.english_name + ")" : ''}
                                                </span>
                                            </React.Fragment>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="apply-button-container">
                                {updated && (
                                    <button onClick={handleApplyButtonClick} disabled={!updated} className="apply-button">
                                        Apply
                                    </button>
                                )}
                            </div>
                            <div ref={bottomRef} />
                        </div>
                    </div>
                )
                }

            </React.Fragment>

        </>
    );
};
export default ModalAttributeSelection