// SwipeCards.js
import React, { useState } from 'react';
import Card from './Card';
import MovieCard from './MovieCard';
import FoodCard from './CustomCards/FoodCard';
import WorkoutCard from './CustomCards/WorkoutCard';
import './SwipeCards.css';
import axios from 'axios'; // Import Axios for making HTTP requests
import { API_URL } from '../config';
import { postError, postSuccess } from '../utils/ToastMessage';

const SwipeCards = ({ cardsFromParent, userId, fetchData }) => {
    const [topCardIndex, setTopCardIndex] = useState(0);

    const onButtonClick = async (buttonIndex, buttonText) => {
        // Call REST API to move card out to the left
        const postBody = {
            UserId: userId,
            UserProblemId: cardsFromParent.UserProblem.id,
            type: 'UserDecisionMap',
            results: []
        };

        postBody.results.push({
            ...cardsFromParent.DecisionCards[topCardIndex],
            UserDecisionActionId: buttonIndex,
            UserDecisionActionText: buttonText
        });

        try {
            // Save the action in the DB
            await axios.post(API_URL + '/api/saveUserDecisionAction', postBody);

            // Save the action locally
            // Using Array.prototype.push.apply()
            Array.prototype.push.apply(cardsFromParent.PastDecisions, postBody.results);
            //cardsFromParent.PastDecisions.push(postBody.results);

            // Notify user of success
            postSuccess('Your choice was saved.');
        } catch (error) {
            if (error.response.status === 401) {
                postError('Please login again.');
            }
            else {
                postError('Error encountered while saving the data. Please try again.');
            }

            console.error('Error saving items:', error);
        }

        const newIndex = topCardIndex + 1;
        if (newIndex >= cardsFromParent.DecisionCards.length) {
            fetchData();
        }
        else {
            setTopCardIndex(newIndex);
        }
    };

    const onUpdateDecisionClick = async (decisionCard) => {
        // Call REST API to update the card
        const postBody = {
            UserId: userId,
            UserProblemId: cardsFromParent.UserProblem.id,
            type: 'UserDecisionMap',
            results: [decisionCard]
        };

        try {
            // Save the action in the DB
            await axios.post(API_URL + '/api/updateDecisionAction', postBody);

            // Save the action locally
            const indexToReplace = cardsFromParent.PastDecisions.findIndex(card => card.id === decisionCard.id);

            if (indexToReplace !== -1) {
                cardsFromParent.PastDecisions[indexToReplace] = decisionCard;
            }

            // Notify user of success
            postSuccess('Your choice were updated successfully.');
        } catch (error) {
            if (error.response.status === 401) {
                postError('Please login again.');
            }
            else {
                postError('Error updating the data. Please try again.');
            }

            console.error('Error updating items:', error);
        }
    }

    return (
        <div className="card-stack">
            {cardsFromParent.UserProblem.OutputType && cardsFromParent.UserProblem.OutputType === 'MovieCard' ?
                <div>
                    {cardsFromParent.DecisionCards.slice(topCardIndex, topCardIndex + 1).map((card) => (
                        <MovieCard
                            key={card.id}
                            card={card}
                            userProblem={cardsFromParent.UserProblem}
                            onButtonClick={onButtonClick}
                            pastDecisions={cardsFromParent.PastDecisions}
                            onUpdateDecisionClick={onUpdateDecisionClick}
                            userCountryCode={cardsFromParent.UserCountryCode}
                        />
                    ))
                    }
                </div>
                :
                cardsFromParent.UserProblem.OutputType && cardsFromParent.UserProblem.OutputType === 'TextCard' ?
                    <div>
                        {cardsFromParent.DecisionCards.slice(topCardIndex, topCardIndex + 1).map((card) => (
                            <Card
                                key={card.id}
                                card={card}
                                userProblem={cardsFromParent.UserProblem}
                                onButtonClick={onButtonClick}
                                pastDecisions={cardsFromParent.PastDecisions}
                                onUpdateDecisionClick={onUpdateDecisionClick}
                            />
                        ))
                        }
                    </div>
                    :
                    cardsFromParent.UserProblem.OutputType && cardsFromParent.UserProblem.OutputType === 'FoodCard' ?
                        <div>
                            {cardsFromParent.DecisionCards.slice(topCardIndex, topCardIndex + 1).map((card) => (
                                <FoodCard
                                    key={card.id}
                                    card={card}
                                    userProblem={cardsFromParent.UserProblem}
                                    onButtonClick={onButtonClick}
                                    pastDecisions={cardsFromParent.PastDecisions}
                                    onUpdateDecisionClick={onUpdateDecisionClick}
                                />
                            ))
                            }
                        </div>
                        :
                        cardsFromParent.UserProblem.OutputType && cardsFromParent.UserProblem.OutputType === 'WorkoutCard' ?
                            <div>
                                {cardsFromParent.DecisionCards.slice(topCardIndex, topCardIndex + 1).map((card) => (
                                    <WorkoutCard
                                        key={card.id}
                                        card={card}
                                        userProblem={cardsFromParent.UserProblem}
                                        onButtonClick={onButtonClick}
                                        pastDecisions={cardsFromParent.PastDecisions}
                                        onUpdateDecisionClick={onUpdateDecisionClick}
                                    />
                                ))
                                }
                            </div>
                            :
                            <></>
            }
            <div className="ai-warrning">
                AI can make mistakes. Check important info.
            </div>
        </div>
    );
};

export default SwipeCards;
