import React, { useState } from 'react';
import './MultiSelectDropdown.css'; // Import CSS file
import Modal from '../modals/AdditionModal';

const RepeatedButtonsWithInput = ({ parent, options, attributeId, onSubmitClick }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [callingOption, setCallingOption] = useState();

    const handleButtonClick = (option) => {
        setCallingOption(option);
        setIsOpen(true); // Open the modal when a button is clicked
    };

    const handleCloseModal = () => {
        setIsOpen(false); // Close the modal
    };

    return (
        <div className="grid-container-msd">
            <div className="grid-msd">
                <div className="input-container-msd">
                    <div className="buton-container-rbi">

                        <div className="button-toadd-rbi">
                            <button onClick={() => handleButtonClick(parent.Description)} >{parent.Description}</button>
                        </div>
                    </div>
                </div>
                <Modal isOpen={isOpen} onClose={handleCloseModal} callingOption={callingOption} attributeId={attributeId} onSubmitClick={onSubmitClick} parent={parent} optionsFromParent={options} />
            </div>
        </div>
    );

};

export default RepeatedButtonsWithInput;
