// frontend/src/App2.js

import React from 'react';
import Home from './pages/Home';
import LoginPage from './pages/Login';
import ActivatePage from './pages/Activate';
import SignUpPage from './components/Signup';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import CalendarTask from './pages/CalendarTask';
import LandingPage from './pages/HomePage';
import Decision from './pages/Decision'
import Terms from './pages/Terms'
import ProtectedRoute from './components/ProtectedRoute'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App2 = () => {
  return (
    <div >
      <ToastContainer />
      <Router>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/activate/:token" element={<ActivatePage />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ResetPassword/:token?" element={<ResetPassword />} />
          <Route path="/Home" element={<ProtectedRoute Component={< Home />} />} />
          <Route path="/CalendarTask" element={<ProtectedRoute Component={< CalendarTask />} />} />
          <Route path="/Home/p/:id" element={<ProtectedRoute Component={<Decision />} />} />
          {/*  Add more protected routes as needed */}
        </Routes>
      </Router>
    </div>
  );
};

export default App2;
