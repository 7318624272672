import React, { useState, useEffect } from 'react';
import {
    Grid, TextField, Tooltip, Tabs, Tab, Box
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import TaskView from '../CalendarTasks/TaskView';
import NewEventModal from '../../modals/NewCalendarEvent';
import ErrorBoundary from '../../utils/ErrorBoundary';
import '../Card.css';

const { IconActions, IconData, IconColor } = require('../CardActions/ActionMapping');
const localizer = momentLocalizer(moment);

const Card = ({ card, userProblem, onButtonClick }) => {
    const [events, setEvents] = useState([]);
    const [placeholder, setPlaceholder] = useState(null);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [lastSelectedDate, setLastSelectedDate] = useState(moment().format('YYYY-MM-DD'));
    const [tabIndex, setTabIndex] = useState(0);

    // Handle button click action
    const handleButtonClick = (item, card) => {
        onButtonClick(item.id, item.text);

        // Perform the action for the action class (if defined)
        const action = IconActions[item.action];
        if (action) {
            action.performAction(events);
        }
    };

    // Function to color code events based on type
    const eventStyleGetter = (event) => {
        let color = '';
        switch (event.type) {
            case 'Calendar Event':
                color = '#1d0ea7';
                break;
            case 'Finance':
                color = 'green';
                break;
            case 'Food':
                color = 'darkcyan';
                break;
            default:
                color = 'orange';
                break;
        }
        return {
            style: {
                backgroundColor: color,
            },
        };
    };

    // Handle event click
    const handleEventClick = (event) => {
        renderEventDetails(event);
    };

    // Render event details in flyout
    const renderEventDetails = (event) => {
        if (event) {
            setPlaceholder(<NewEventModal isOpen={true} onClose={handleCloseModal} onSubmitClick={null} existingCard={event} callingOption={"View"} />);
        }
    };

    // Close the modal
    const handleCloseModal = () => {
        setPlaceholder(null);
    };

    // Handle date change for start date
    const handleStartDateChange = async (e) => {
        const newSelectedDate = moment(e.target.value).format('YYYY-MM-DD');
        if (newSelectedDate !== lastSelectedDate) {
            const today = moment(events[0]?.start); // Ensure events[0] exists before accessing start date
            const selectedDate = moment(e.target.value);
            const diffDays = selectedDate.diff(today, 'days');
            setEvents(events.map((event) => ({
                ...event,
                start: event.start.add(diffDays, 'days'),
                end: event.end.add(diffDays, 'days'),
            })));
            setSelectedDate(selectedDate);
            setLastSelectedDate(newSelectedDate);
        }
    };

    // Initialize events on component mount
    useEffect(() => {
        const transformedEvents = Object.keys(card).slice(2).map((day, index) => ({
            title: `Day ${index + 1} Workout plan`,
            start: moment().startOf('day').add(index, 'days').toDate(),
            end: moment().startOf('day').add(index, 'days').toDate(),
            occurances: '',
            frequency: '',
            customFrequency: '',
            isActive: true,
            notes: card[day],
            type: 'Workout',
        }));

        setEvents(transformedEvents);
    }, [card]); // Update events when card changes

    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    return (
        <div className="card">
            <div className='wc-Picker-grid'>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <div className="wc-card-buttons">
                            <TextField
                                type="date"
                                name="StartDate"
                                label="Start Date"
                                value={moment(selectedDate).format('YYYY-MM-DD')}
                                onChange={handleStartDateChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </div>
                    </Grid>
                    {userProblem && userProblem.Buttons && userProblem.Buttons.length > 0 && (
                        userProblem.Buttons.map((item) => (
                            <Grid item xs={3}>
                                <div className="wc-card-buttons">
                                    <Tooltip key={item.id} title={item.Description} aria-label={item.Description}>
                                        <span className={`icon-circle ${IconColor[item.text]}`} onClick={() => handleButtonClick(item, card)}>
                                            <FontAwesomeIcon icon={IconData[item.text]} style={{ color: 'white' }} size="2x" aria-label={item.text} />
                                        </span>
                                    </Tooltip>
                                </div>
                            </Grid>
                        ))
                    )}

                </Grid>
            </div>

            <div>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                    <Tab label="Tasks" />
                    <Tab label="Calendar" />
                </Tabs>
                <Box role="tabpanel" hidden={tabIndex !== 1}>
                    {tabIndex === 1 && (
                        <ErrorBoundary>
                            <Calendar
                                localizer={localizer}
                                events={events}
                                date={selectedDate.toDate()} // Convert moment to Date object
                                onNavigate={(date) => setSelectedDate(moment(date))}
                                startAccessor="start"
                                endAccessor="end"
                                views={['month']}
                                style={{ height: 500 }}
                                selectable
                                eventPropGetter={eventStyleGetter}
                                onSelectEvent={handleEventClick}
                            // ref={calendarRef} // Remove this if not needed
                            />
                        </ErrorBoundary>
                    )}
                </Box>
                <Box role="tabpanel" hidden={tabIndex !== 0}>
                    {tabIndex === 0 && events.length > 0 && <TaskView events={events} onEventClick={handleEventClick} />}
                </Box>
                {placeholder}
            </div>
        </div>
    );
};

export default Card;
