// ModalPastChoices.js
import React, { useState, useEffect } from 'react';
import './ModalPastChoices.css'; // Import CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Tooltip } from '@material-ui/core';

const { IconData, IconColor } = require('../components/CardActions/ActionMapping')

const ModalPastChoices = ({ isOpen, onClose, data, filterId, buttons, onUpdateDecisionClick }) => {
    const [pastDecisions, setPastDecisions] = useState(data);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        if (refresh) {
            setRefresh(false);
        }

    }, [refresh]);

    const onUpdateDecision = (card, newDecisionId, newDecisionText) => {
        card.UserDecisionActionId = newDecisionId;
        card.UserDecisionActionText = newDecisionText;
        onUpdateDecisionClick(card);

        const indexToReplace = data.findIndex(item => item.id === card.id);

        if (indexToReplace !== -1) {
            data[indexToReplace] = card;
        }

        setPastDecisions(data);
        setRefresh(true);
    };

    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={onClose}>X</button>
                        <h2>Past actions factors in recommendation</h2>
                        <Grid container spacing={3} className="gridContainer">
                            <Grid item xs={8} className="bordered-grid centered-text">
                                <span>Items</span>
                            </Grid>
                            <Grid item xs={4} className="bordered-grid centered-text">
                                <span>Move to</span>
                            </Grid>
                        </Grid>
                        {pastDecisions && pastDecisions.filter(item => item.UserDecisionActionId === filterId).map((item, index) => (
                            <Grid container spacing={3} className="gridContainer">
                                <Grid item xs={8} className="bordered-grid">
                                    <span>{(item.CinemaType !== undefined && item.CinemaType === 'TV') ? item.name : item.title}</span>
                                </Grid>
                                <Grid item xs={4} className="bordered-grid">
                                    <Grid container spacing={1} >
                                        {buttons && buttons.length > 0 && (
                                            buttons.filter(bitem => bitem.id >= 1 && bitem.id <= 3 && bitem.id !== filterId).map((citem, index) => (
                                                <Grid item xs={5} >
                                                    <Tooltip title={citem.Description} aria-label={citem.Description} zIndex={2000}>
                                                        <span className={`icon-small-circle ${IconColor[citem.text]}`} onClick={() => onUpdateDecision(item, citem.id, citem.text)}>
                                                            <FontAwesomeIcon key={citem.id} icon={IconData[citem.text]} style={{ color: 'white' }} size="x" aria-label={citem.text}></FontAwesomeIcon>
                                                        </span>
                                                    </Tooltip>
                                                </Grid>
                                            )))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

export default ModalPastChoices;
