// frontend/src/pages/ActivatePage.js

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header';
import axios from 'axios';
import './Activate.css'; // Import CSS file
import { API_URL } from '../config';
import { postError, postSuccess } from '../utils/ToastMessage';
import { Typography } from '@material-ui/core';

const Activate = () => {
    const { token } = useParams();
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const validateToken = async () => {
            try {
                if (!token) {
                    setLoading(false);
                    return;
                }

                const apiURLFormatted = `${API_URL}/api/activate/${token}`
                const response = await axios.get(apiURLFormatted);

                if (response.status === 200) {
                    postSuccess(response.data);
                    setStatus()
                    // Redirect to the home protected page
                    navigate('/Login');
                } else {
                    throw new Error('Token validation failed');
                }
            } catch (err) {
                postError(err.response.data);
                setError(err.response.data);
            } finally {
                setLoading(false);
            }
        };

        validateToken();
    }, [token, navigate]);

    return (
        <div className="homepage-container">
            <Header />
            <Typography variant="h4">Activate Account</Typography>
            <div>
                {loading ? (
                    <div className="loading-animation-activate">
                        <div className="placeholder-boxe-activates">
                            <div className="placeholder-box-activate"></div>
                        </div>
                    </div>
                ) : error ? (
                    <p>{error}</p>
                ) : (
                    <p>{status}</p>
                )}
            </div>
        </div>
    );
};

export default Activate;
