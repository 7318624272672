import React, { useState, useEffect } from 'react';
import './AdditionModal.css'; // Import CSS file
import YouTube from 'react-youtube';

const ModalVideoPlayer = ({ isOpen, onClose, data }) => {
    const [selectedVideoId, setSelectedVideoId] = useState(data[0]);

    const handleThumbnailClick = (videoId) => {
        setSelectedVideoId(videoId);
    };

    useEffect(() => {
        setSelectedVideoId(data[0]);
    }, [data]);

    const opts = {
        width: '100%',
        height: '400px',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
        },
    };

    const onReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    };

    return (
        <>
            {isOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <button className="close-button" onClick={onClose}>X</button>
                        {data !== undefined && data !== '' ?
                            < div className="youtube-gallery">
                                <div className="main-video-container">
                                    <YouTube videoId={selectedVideoId} opts={opts} onReady={onReady} />
                                </div>
                                <div className="vp-thumbnail-container">
                                    {data.map((videoId, index) => (
                                        <img
                                            key={index}
                                            src={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                                            alt={`Thumbnail ${index + 1}`}
                                            onClick={() => handleThumbnailClick(videoId)}
                                            className={`vp-thumbnail ${videoId === selectedVideoId ? 'selected' : ''}`}
                                        />
                                    ))}
                                </div>
                            </div>
                            :
                            <>Couldn't find any trailers</>
                        }
                    </div>
                </div >
            )}
        </>
    );
};

export default ModalVideoPlayer