import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faHome, faSignInAlt, faSignOutAlt, faUserPlus, faLock, faListUl } from '@fortawesome/free-solid-svg-icons';
import './Header.css';

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token'));
  const [showMenu, setShowMenu] = useState(true);
  const navigate = useNavigate();

  const onLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    setIsLoggedIn(false);
    navigate('/login')
  };

  useEffect(() => {
    if (!isLoggedIn) {
      setShowMenu(false);
    }

    // Check if the viewport width is greater than a certain threshold (e.g., 600 pixels)
    const isDesktop = window.innerWidth > 600;

    // Set the initial value of showMenu based on whether it's a desktop or mobile
    setShowMenu(isDesktop);
  }, [isLoggedIn]); // Empty dependency array ensures this effect runs only once after initial render


  return (
    <header className="sticky-header">
      <div className="header-container">
        <div className="logo-container">
          <Link to="/home">
            <img className='logo' src='/genie.gif' alt='Genie'></img>
          </Link>
        </div>
        <div className={`menu-container ${showMenu ? 'show' : ''}`}>
          {isLoggedIn ? (
            <>
              <div className="menu-item">
                <Link to="/Home" style={{ color: 'white' }} >
                  <FontAwesomeIcon icon={faHome} style={{ color: 'white' }} />
                  &nbsp;Home
                </Link>
              </div>
              <div className="menu-item navbar-link">
                <Link to="/CalendarTask" style={{ color: 'white' }} >
                  <FontAwesomeIcon icon={faListUl} style={{ color: 'white' }} />
                  &nbsp;Calendar/Tasks
                </Link>
              </div>
              <div className="menu-item navbar-link">
                <Link to="/ResetPassword" style={{ color: 'white' }} >
                  <FontAwesomeIcon icon={faLock} style={{ color: 'white' }} />
                  &nbsp;Change Password
                </Link>
              </div>
              <div className="menu-item navbar-link">
                <button onClick={onLogout}>
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  &nbsp;Logout
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="menu-item navbar-link">
                <Link to="/login" style={{ color: 'white' }}>
                  <FontAwesomeIcon icon={faSignInAlt} style={{ color: 'white' }} />
                  &nbsp;Login
                </Link>
              </div>
              <div className="menu-item navbar-link">
                <Link to="/signup" style={{ color: 'white' }} >
                  <FontAwesomeIcon icon={faUserPlus} style={{ color: 'white' }} />
                  &nbsp;Sign up
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="hamburger-icon" onClick={() => setShowMenu(!showMenu)}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>
    </header>
  );
};

export default Header;
